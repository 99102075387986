import React, { useState, useCallback } from "react";
import { Box, FormControl, InputAdornment, TextField, Typography, useTheme, Autocomplete } from "@mui/material";
import ReusableButton from "./ReusableButton";
import { encodeTitle } from "utility/helper";

const DocumentationSearch = ({ props }) => {
  const { handleSearch, articles } = props;
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);
  const theme = useTheme();

  const handleChange = useCallback(
    (e) => {
      const value = e.target.value;
      setQuery(value);
      if (value && articles.length > 0) {
        const filteredSuggestions = articles
          .filter((article) => article.ContentTitle.toLowerCase().includes(value.toLowerCase()))
          .map((article) => article.ContentTitle);
        setSuggestions(filteredSuggestions);
        setShowDropdown(true);
      } else {
        setShowDropdown(false);
      }
    },
    [articles]
  );

  const handleSuggestionClick = (suggestion) => {
    handleSearch(suggestion);
    setShowDropdown(false);
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
      <FormControl sx={{ m: 1, maxWidth: "676px", width: "90%" }} variant="outlined">
        <Autocomplete
          freeSolo
          options={suggestions}
          renderInput={(params) => (
            <TextField
              {...params}
              id="search-input"
              placeholder="Ask any question..."
              type="text"
              value={query}
              onChange={handleChange}
              sx={{
                position: "relative",
                zIndex: 10,
                backgroundColor: theme.palette.text.light,
                borderRadius: "5px",
                "& .MuiOutlinedInput-notchedOutline": {
                  borderColor: theme.palette.border.border1,
                },
                "&.MuiOutlinedInput-root.MuiInputBase-root": {
                  paddingRight: "5px",
                  paddingLeft: "20px",
                  backgroundColor: theme.palette.text.light,
                  color: theme.palette.text.caption,
                },
                fontSize: "20px",
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: query ? null : (
                  <InputAdornment position="end">
                    <ReusableButton onClick={() => handleSearch(query)} title="Search" />
                  </InputAdornment>
                ),
              }}
            />
          )}
          onInputChange={(event, newInputValue) => {
            setQuery(newInputValue);
          }}
          onChange={(event, newValue) => {
            handleSuggestionClick(newValue);
          }}
          open={showDropdown}
          onClose={() => setShowDropdown(false)}
        />
      </FormControl>
      <Box
        display={"flex"}
        flexWrap={"wrap"}
        gap={"15px"}
        alignItems={"center"}
        mt={2}
        ml={1}
        sx={{
          justifyContent: { xs: "center", md: "flex-start" },
        }}
      >
        <Typography variant="title2" fontWeight={700} color={theme.palette.text.description2}>
          Popular Things:
        </Typography>
        <ReusableButton link={`/docs?t=${encodeTitle("Getting Started")}`} title="Getting Started" />
      </Box>
    </Box>
  );
};

DocumentationSearch.displayName = "DocumentationSearch";

export default DocumentationSearch;
