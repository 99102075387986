import React, { useEffect, useState } from "react";
import {
  Box,
  List,
  ListItem,
  Typography,
  Grid,
  IconButton,
  Drawer,
  useTheme,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import bgUrl from "../images/hero-header/staticBackground.svg";
import Container from "./Container";
import PropTypes from "prop-types";
import PortableTextComponent from "./PortableTextComponent";

const sortDataWithGettingStartedFirst = (data) => {
  const gettingStartedItem = data.find((item) => item.Slug.current === "getting-started");
  const otherItems = data.filter((item) => item.Slug.current !== "getting-started");

  if (gettingStartedItem) {
    return [gettingStartedItem, ...otherItems];
  } else {
    return data;
  }
};

const DocumentationPage = ({ data = [] }) => {
  const [activeTitle, setActiveTitle] = useState("");
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const theme = useTheme();

  const isLargeScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.down("xl"));

  const getTitle = (title) => {
    if (title.length > 20) {
      return isLargeScreen
        ? title.slice(0, 20) + "..."
        : isExtraLargeScreen
          ? title.slice(0, 40) + "..."
          : title.slice(0, 50) + "...";
    }
    return title;
  };

  useEffect(() => {
    // Extract slug from the URL
    if (typeof window !== "undefined") {
      const urlParams = new URLSearchParams(window.location.search);
      const urlSlug = urlParams.get("t");
      // Find the article based on the slug
      const article = data.find((item) => item.Slug.current === urlSlug);
      if (article) {
        setActiveTitle(article.ContentTitle);
      } else {
        // If no slug matches, set the default title
        setActiveTitle("Getting Started");
      }
    }
  }, [data]);

  const handleURLChange = (slug) => {
    const params = new URLSearchParams(window.location.search);
    params.set("t", slug);
    window.history.replaceState({}, "", `${window.location.pathname}?${params}`);
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const sortedData = sortDataWithGettingStartedFirst(data);

  return (
    <Box
      sx={{
        position: "relative",
        backgroundImage: `url(${bgUrl})`,
        paddingY: 8,
      }}
    >
      <Drawer anchor="left" open={sidebarOpen} onClose={toggleSidebar} sx={{ display: { md: "none" } }}>
        <List component={"nav"} aria-label="Article titles" sx={listStyle(theme)}>
          {sortedData.map((item) => (
            <ListItem
              key={item.ContentTitle}
              onClick={() => {
                setActiveTitle(item.ContentTitle);
                handleURLChange(item.Slug.current);
                toggleSidebar();
              }}
            >
              <Typography variant="title3">
                {item.ContentTitle.length > 30 ? item.ContentTitle.slice(0, 30) + "..." : item.ContentTitle}
              </Typography>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Container maxWidth={"2000px"}>
        <Grid container justifyContent={"space-between"} position={"relative"}>
          <Grid component={"nav"} item xs={12} md={4} lg={4} sx={{ display: { xs: "none", md: "block" } }}>
            <List component={"ul"} aria-label="Sidebar navigation article titles" sx={listStyle(theme)}>
              {sortedData.map((item, i) => (
                <Tooltip key={i} title={item.ContentTitle}>
                  <ListItem
                    key={item.ContentTitle}
                    onClick={() => {
                      setActiveTitle(item.ContentTitle);
                      handleURLChange(item.Slug.current);
                    }}
                    sx={sidebarItemStyle(activeTitle === item.ContentTitle, theme)}
                  >
                    <Typography variant="title3" color={activeTitle === item.ContentTitle ? theme.palette.text.light : theme.palette.text.description2}>
                      {getTitle(item.ContentTitle)}
                    </Typography>
                  </ListItem>
                </Tooltip>
              ))}
            </List>
          </Grid>
          <Grid
            item
            xs={12}
            md={7.5}
            lg={7.5}
            component={"article"}
            sx={{
              zIndex: 10,
              position: "relative",
            }}
          >
            <IconButton
              sx={{
                display: { md: "none" },
                padding: 1,
                borderRadius: "8px",
                backgroundColor: theme.palette.text.primaryBlue,
                color: theme.palette.text.light,
                mb: 2,
                "&:hover": {
                  backgroundColor: `${theme.palette.text.primaryBlue}DD`,
                },
              }}
              onClick={toggleSidebar}
            >
              <MenuIcon />
              <Typography variant="footer" sx={{ ml: 1 }}>
                More Articles
              </Typography>
            </IconButton>
            <Typography
              variant="title"
              component={"h1"}
              color={theme.palette.text.description2}
              sx={{
                marginBottom: "20px",
                display: { xs: "block", md: "none" },
              }}
            >
              {activeTitle}
            </Typography>
            {/* Display content for the selected article */}
            {sortedData.find((item) => item.ContentTitle === activeTitle)?.Content && (
              <>
                <Typography
                  variant="title"
                  color={theme.palette.text.description2}
                  sx={{
                    marginBottom: "20px",
                    display: { xs: "none", md: "block" },
                  }}
                >
                  {activeTitle}
                </Typography>
                <PortableTextComponent
                  props={sortedData.find((item) => item.ContentTitle === activeTitle)?.Content}
                />
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

const listStyle = (theme) => ({
  zIndex: 10,
  position: "relative",
  width: "fit-content",
  maxHeight: { md: "100vh" },
  overflow: { md: "auto" },
  flexShrink: 0,
  paddingRight: { md: "40px" },
  paddingTop: { md: 0 },
  "&::-webkit-scrollbar": {
    width: "7px",
    backgroundColor: theme.palette.text.light,
    borderRadius: "20px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: theme.palette.text.primaryBlue,
    borderRadius: "20px",
    width: "3px",
    border: `2px solid ${theme.palette.text.light}`,
  },
  m: { xs: "30px 20px", md: 0 },
});

const sidebarItemStyle = (isActive, theme) => ({
  cursor: "pointer",
  backgroundColor: isActive ? theme.palette.text.primaryBlue : "transparent",
  color: isActive ? theme.palette.text.light : theme.palette.text.description2,
  padding: "14px 30px",
  borderRadius: "25px",
  marginBottom: "5px",
  transition: "all .3s ease-out",
  "&:hover": {
    color: "white",
    backgroundColor: theme.palette.text.primaryBlue,
  },
  whiteSpace: "nowrap",
});

DocumentationPage.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      ContentTitle: PropTypes.string,
      slug: PropTypes.string,
      Content: PropTypes.string,
    })
  ),
};

export default DocumentationPage;
