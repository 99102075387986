import React, { useState, useEffect } from "react";
import { Box, Typography } from "@mui/material";
import DocumentationPage from "../components/DocumentationPage";
import DocumentationSearch from "../components/DocumentationSearch";
import HeroHeader from "../components/HeroHeader";
import { Main } from "../layouts";
import { graphql, navigate } from "gatsby";
import { encodeTitle, decodeTitle } from "../utility/helper";
import ReusableButton from "../components/ReusableButton";
import bgUrl from "../images/hero-header/staticBackground.svg";

const Documentations = ({ data, error, location }) => {
  if (error) {
    return <div>Error fetching data...</div>;
  }

  const sanityData = data?.allSanityArticle?.nodes || [];
  const [activeArticle, setActiveArticle] = useState(null);
  
  const defaultArticleSlug = "getting-started";

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const encodedTitle = searchParams.get("t");
    
    if (encodedTitle) {
      const decodedTitle = decodeTitle(encodedTitle);
      const article = sanityData.find((article) => article.Slug.current === decodedTitle);
      setActiveArticle(article || null);
    } else {
      const defaultArticle = sanityData.find((article) => article.Slug.current === defaultArticleSlug);
      setActiveArticle(defaultArticle || null);
      if (defaultArticle) {
        const encodedDefaultTitle = encodeTitle(defaultArticleSlug);
        navigate(`/docs?t=${encodedDefaultTitle}`, { replace: true });
      }
    }
  }, [location.search, sanityData]);

  const handleSearch = (query) => {
    if (!query) {
      setActiveArticle(null);
    }

    const filteredArticles = sanityData.filter((article) => {
      const { ContentTitle, Tags } = article;
      const searchTerm = query.toLowerCase().replace(/\s/g, "");
      return (
        ContentTitle.toLowerCase().replace(/\s/g, "").includes(searchTerm) ||
        Tags.some((tag) => tag.toLowerCase().replace(/\s/g, "").includes(searchTerm))
      );
    });

    if (filteredArticles.length > 0) {
      const firstResult = filteredArticles[0];
      const encodedTitle = encodeTitle(firstResult.Slug.current);
      navigate(`/docs?t=${encodedTitle}`);
    } else {
      navigate(`/docs?t=${encodeTitle(query)}`);
    }
  };

  return (
    <Box sx={{ overflowX: "hidden" }}>
      <Main bgcolor={"background.paper"}>
        <HeroHeader
          title="Appnigma AI Overview"
          description="Comprehensive guide to building seamless Salesforce integrations with our platform"
          highlightText={{
            title: {
              text: "Overview",
            },
          }}
          Component={DocumentationSearch}
          Props={{ handleSearch, articles: sanityData }} // Pass all articles to search component
        />
        {activeArticle === null ? (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              gap: 2,
              backgroundImage: `url(${bgUrl})`,
              paddingY: 8,
            }}
          >
            <Typography variant="h5" component="h2" gutterBottom>
              No search results found.
            </Typography>
            <ReusableButton onClick={() => navigate("/docs")} title="Back to Docs" />
          </Box>
        ) : (
          <DocumentationPage data={sanityData} activeArticle={activeArticle} />
        )}
      </Main>
    </Box>
  );
};

export const query = graphql`
  query {
    allSanityArticle(filter: { articleType: { eq: "TechArticle" } }) {
      nodes {
        ContentTitle: headline
        Content: _rawDescription
        Tags: tags
        Slug: slug {
          current
        }
      }
    }
  }
`;

export default Documentations;
